import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import text, { buttonStyle } from "styles/text";
import media from "styles/media";
import { ReactComponent as BallsSVG } from "svg/reliability.svg";
import { ReactComponent as BottomLineSVG } from "svg/overviewLine.svg";
import { ReactComponent as TopLineSVG } from "svg/oVTabLine.svg";
import RegisterNowButton from "components/RegisterNowButton";

const Overview = (): JSX.Element => {
  const row1 = useRef<HTMLDivElement>(null);
  const row2 = useRef<HTMLDivElement>(null);
  const row3 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (row1.current) {
      const tl = gsap.timeline({ repeat: -1 });
      tl.to(".row__1", {
        x: "-100%",
        ease: "none",
        duration: 20,
      }).to(".row__1", { x: 0, duration: 0 });
    }
    if (row2.current) {
      const tl = gsap.timeline({ repeat: -1 });
      tl.to(".row__2", {
        x: "-100%",
        ease: "none",
        duration: 80,
      }).to(".row__2", { x: 0, duration: 0 });
    }
    if (row3.current) {
      const tl = gsap.timeline({ repeat: -1 });
      tl.to(".row__3", {
        x: "-100%",
        ease: "none",
        duration: 20,
      }).to(".row__3", { x: 0, duration: 0 });
    }
  }, []);

  return (
    <Wrapper id="overview">
      <Overline>01 - Overview</Overline>
      <Title>
        One jam-packed day of data reliability engineering - trends, tools, and
        teams
      </Title>
      <Row>
        <p>
          <span>
            <strong>First held in December 2021</strong>, DRE Con is the only
            conference to focus solely on data reliability engineering - the
            tools, processes, and people that make up the DRE ecosystem.
          </span>
        </p>
        <p>
          <span>
            Join us virtually on <strong>April 19, 2023</strong> for hands-on
            workshops, live Q&A, and presentations from standouts in the data
            space. What brings it all together? The willingness to dive straight
            into the most complex data reliability challenges of today and
            tomorrow.
          </span>
        </p>
      </Row>
      <Row>
        <RegisterNowButtonContainer>
          <RegisterNowButton>Register Now</RegisterNowButton>
        </RegisterNowButtonContainer>
      </Row>
      <Reliability />
      <BottomLine />
      <TopLine />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 1.67vw 0 0.42vw 13.39vw;
  overflow: hidden;
  position: relative;
  ${media.tablet} {
    padding: 56.95vw 11.99vw 7.19vw;
  }
  ${media.mobile} {
    padding: 0 4vw 12.8vw;
    overflow: visible;
  }
`;
const Title = styled.h3`
  ${text.fullWidth.H3};
  width: 44.94vw;
  margin-bottom: 2.38vw;
  position: relative;
  z-index: 1;
  ${media.tablet} {
    ${text.tablet.H3};
    width: 69.54vw;
    margin-bottom: 3.6vw;
  }
  ${media.mobile} {
    ${text.mobile.H3}
    margin-bottom: 8vw;
    width: 100%;
  }
`;
const Overline = styled.p`
  ${text.fullWidth.Overline};
  margin-bottom: 1.19vw;
  position: relative;
  z-index: 1;
  ${media.tablet} {
    ${text.tablet.Overline};
    width: 32.37vw;
    margin-bottom: 3.6vw;
  }
  ${media.mobile} {
    ${text.mobile.Overline}
    margin-bottom: 8vw;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-bottom: 2.86vw;

  p {
    ${text.fullWidth.Body};
    width: 20.77vw;
    margin-right: 1.55vw;
    display: flex;
    align-self: flex-start;
  }

  ${media.tablet} {
    p {
      ${text.tablet.Body};
      width: 36.81vw;
    }
  }
  ${media.mobile} {
    display: block;
    p {
      ${text.mobile.Body}
      width: 100%;
      margin-bottom: 10vw;
      &:last-of-type {
        margin-bottom: 10.7vw;
      }
    }
  }
  ${media.fullWidth} {
  }
`;

const RegisterNowButtonContainer = styled.div`
  ${buttonStyle};
  margin-right: 1.79vw;
  ${media.tablet} {
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const BottomLine = styled(BottomLineSVG)`
  margin-left: 5.54vw;
  width: 31.07vw;
  height: 22.41vw;
  ${media.tablet} {
    width: 26.38vw;
    height: 22.9vw;
    margin-left: 11.63vw;
  }
  ${media.mobile} {
    display: none;
  }
  ${media.fullWidth} {
  }
`;

const TopLine = styled(TopLineSVG)`
  display: none;
  ${media.tablet} {
    display: inline-block;
    position: absolute;
    top: 30.34vw;
    left: 0;
    width: 22.78vw;
    height: 15.23vw;
  }
  ${media.mobile} {
    display: none;
  }
  ${media.fullWidth} {
  }
`;

const Reliability = styled(BallsSVG)`
  position: absolute;
  width: 28.99vw;
  height: 40.42vw;
  left: 65.18vw;
  top: 3.81vw;
  z-index: 0;
  ${media.tablet} {
    width: 36.81vw;
    height: 51.56vw;
    left: 44.72vw;
    top: 5.4vw;
  }
  ${media.mobile} {
    width: 92vw;
    height: 128.3vw;
    left: 4vw;
    top: -141.6vw;
    opacity: 0.2;
  }
`;

export default Overview;
