import { Link } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";
import styles from "styles"
import colors from "styles/colors";
import media from "styles/media";
import text from "styles/text";
import { ReactComponent as CloseSpeakersSVG } from "svg/closeSpeakers.svg";
import { ReactComponent as DREArrowSVG } from "svg/dreArrow.svg";
import { ReactComponent as ExpandButtonSVG } from "svg/expandBtn.svg";

function ScheduleEventSpeaker({ data }: {
  data: {
    company: string
    image: {
      file: {
        url: string
      },
    },
    jobTitle: string
    name: string
  },
}) {
  return (
    <div className="speaker">
      <div className="speaker--figure">
        <HeadShot src={data.image.file.url} />
      </div>
      <div className="speaker--body">
        <h3 className="speaker--name">
          {data.name}
        </h3>
        <div className="speaker--position">
          {data.jobTitle}
        </div>
        <div className="speaker--company">
          {data.company}
        </div>
        <Link
          to={`/${data.name.split(" ").join("-").toLowerCase()}`}
          className="speaker--link">
          View Bio
          <DREArrow />
        </Link>
      </div>
    </div>
  )
}

const ScheduleEvent = ({
  event,
  isMultiTrack,
  track,
}): JSX.Element => {
  const [showDescription, setShowDescription] = useState(false)

  const hasSummary = event.summary !== 'Abstract coming soon.'

  const allSpeakers = event.speakers?.map((speaker: any, i: any, arr: any) => {
    const speakerSlug = speaker.name.split(" ").join("-").toLowerCase();
    if (i < 4) {
      return (
        <SpeakerShot key={speaker.name} href={`/${speakerSlug}`}>
          <HeadShot src={speaker.image.file.url} />
          <p>{speaker.name}</p>
        </SpeakerShot>
      );
    }
    return null
  });

  const allExtraSpeakers = event.speakers?.map((speaker: any, i: any, arr: any) => {
    const speakerSlug = speaker.name.split(" ").join("-").toLowerCase();
    if (i > 2 && arr.length > 4) {
      return (
        <SpeakerShot key={speaker.name} href={`/${speakerSlug}`}>
          <HeadShot src={speaker.image.file.url} />
          <p>{speaker.name}</p>
        </SpeakerShot>
      );
    }
    return null
  });

  return (
    <Card className={`card ${isMultiTrack ? 'card---is-multi-track' : 'card---is-not-multi-track'} ${showDescription ? 'open' : ''}`}>
        <>
          <Topic className="card--topic">
            <Row>
              <Column className="card--column">
                {event.theme && (
                  <Overline>
                    {event.theme}
                  </Overline>
                )}
                <TopicTitle className="">
                  <span>
                    {event.topic}
                  </span>
                  {hasSummary && (
                    <Expand className="expand" onClick={() => setShowDescription(!showDescription)}>
                      <ExpandImg showDescription={showDescription} />
                    </Expand>
                  )}
                </TopicTitle>
                {isMultiTrack && (
                  <div className="speaker-and-track">
                    {event.speakers?.length !== 0 && (
                      <>
                        <SpeakerColumn className="speaker-and-track--speaker">
                          {event.speakers?.length > 0 && (
                            <>
                              <Overline>
                                {event.speakers.length === 1 ? 'Speaker' : 'Speakers'}
                              </Overline>
                              {event.speakers.length === 1 && (
                                <ScheduleEventSpeaker data={event.speakers[0]} />
                              )}
                              {event.speakers.length !== 1 && (
                                <SpeakersContainer>
                                  {allSpeakers?.slice(0, 3)}{" "}
                                  {allSpeakers?.length > 4 && (
                                    <Extra>
                                      4+
                                    </Extra>
                                  )}
                                </SpeakersContainer>
                              )}
                            </>
                          )}
                        </SpeakerColumn>
                      </>
                    )}
                    <Track>
                      Track {track}
                    </Track>
                  </div>
                )}
              </Column>
            </Row>
          </Topic>
          {!isMultiTrack && (
            <>
              {event.speakers?.length !== 0 && (
                <SpeakerColumn className="card--speaker-column">
                  {event.speakers?.length > 0 && (
                    <>
                      <Overline>
                        {event.speakers.length === 1 ? 'Speaker' : 'Speakers'}
                      </Overline>
                      {event.speakers.length === 1 && (
                        <ScheduleEventSpeaker data={event.speakers[0]} />
                      )}
                      {event.speakers.length !== 1 && (
                        <SpeakersContainer>
                          {allSpeakers?.slice(0, 3)}{" "}
                          {allSpeakers?.length > 4 && (
                            <Extra>
                              4+
                            </Extra>
                          )}
                        </SpeakersContainer>
                      )}
                    </>
                  )}
                </SpeakerColumn>
              )}
            </>
          )}
          {allSpeakers?.length > 4 && (
            <ExtraSpeakers className={`extra-${i}`}>
              {allExtraSpeakers}
              <button>
                <CloseSpeakers />
              </button>
            </ExtraSpeakers>
          )}
          <Summary className={`${showDescription ? 'open' : ''}`}>
            {event.summary}
          </Summary>
        </>
    </Card>
  );
};

const Card = styled.div`
  width: 100%;
  background: radial-gradient(55.2% 390.02% at 53.76% -9.22%, #f2f1f1 0%, #e6e6e6 100%);
  border-left: 2px solid ${colors.drePurple};
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 2.38vw;
  padding: ${styles.pxToVw(32)} ${styles.pxToVw(56)};
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
    box-sizing: border-box;
    padding-top: ${styles.pxToVw(32 * 2)};
    padding-bottom: ${styles.pxToVw(32 * 2)};

    .card {
      &--expand-button {
        padding: 0 !important;
        position: absolute !important;
        top: ${styles.pxToVw(40)} !important;
        right: ${styles.pxToVw(20)} !important;
      }
    }
  }

  .card {
    &--topic {
      width: 50%;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  &.card---is-not-multi-track {
    flex-wrap: wrap;

    .card {
      &--speaker-column {
        width: 50%;
        box-sizing: border-box;
        padding-left: ${styles.pxToVw(50)};
      }

      &--expand-button {
        padding: 0;
        position: absolute;
        top: ${styles.pxToVw(40)};
        right: ${styles.pxToVw(20)};
      }
    }

    .event-topic {
      width: 50%;
      margin-right: 74px;
    }

    @media (max-width: 1024px) {
      flex-direction: column;

      .card {
        &--speaker-column {
          margin: ${styles.pxToVw(40)} 0 0;
          width: 50%;
          padding-left: 0;
        }
      }
    }
  }

  &.card---is-multi-track {
    width: 50%;
    flex-direction: column;

    @media (max-width: 1024px) {
      width: 100%;
      box-sizing: border-box;
    }

    .card {
      &--topic {
        width: 100%;
      }

      &--expand-button {
        position: static;
        margin-top: -2px;
        margin-left: ${styles.pxToVw(32)};
      }
    }

    .expand {
      margin-left: ${styles.pxToVw(32)};
      padding: 0;
      position: static;
    }

    .event-topic {
      width: auto;
      margin-right: 0;
    }
  }

  .speaker-and-track {
    display: flex;
    align-items: center;
    margin: ${styles.pxToVw(40)} 0 0;

    &--speaker {
      flex: 1;
    }
  }

  .speaker {
    display: flex;
    font-family: "Suisse Int'l";
  }

  .speaker--figure {
    margin-right: 12px;
  }

  .speaker--body {
    flex: 1;
  }

  .speaker--name {
    ${styles.fontSize(14)}
    font-weight: 400;
    margin-bottom: ${styles.pxToVw(4)};
  }

  .speaker--position {
    ${styles.fontSize(11)}
    font-weight: 300;
    margin-bottom: ${styles.pxToVw(4)};
  }

  .speaker--company {
    ${styles.fontSize(12)}
  }

  .speaker--link {
    ${styles.fontSize(12)}
    color: ${colors.drePurple};
  }

  ${media.tablet} {
    margin-bottom: 4.8vw;
  }
  ${media.mobile} {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }
  ${media.fullWidth} {
  }
`;

const Overline = styled.p`
  ${text.fullWidth.OverlineS};
  margin-bottom: 0.6vw;
  position: relative;
  z-index: 1;
  ${media.tablet} {
    ${text.tablet.Overline};
    margin-bottom: 2.4vw;
    //${text.tablet.OverlineS};
    //margin-bottom: 1.2vw;
  }
  ${media.mobile} {
    ${text.mobile.Overline}
    margin-bottom: 5.3vw;
  }
`;

const Summary = styled.div`
  ${text.fullWidth.Body};
  font-size: 0.95vw;
  line-height: 170%;
  margin: ${styles.pxToVw(18)} 0 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 1s ease-in-out;

  &.open {
    max-height: 999px;
    opacity: 1;
  }

  ${media.tablet} {
    ${text.tablet.Body};
  }
  ${media.mobile} {
    ${text.mobile.Body}
    line-height: 120%;
    letter-spacing: -0.02em;
    width: 100%;
  }
  ${media.fullWidth} {
  }
`;

const Topic = styled.div<{ expanded: boolean; leftColor?: string }>`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.leftColor &&
    `
    align-items: center;
    justify-content: center;
  `}
  //border-left: 5px solid ${(props) => (props.leftColor ? props.leftColor : colors.drePurple)};
  transition: 0.5s;
  ${media.tablet} {
    //padding: 1.8vw 0 1.8vw 3.36vw;
  }
  ${media.mobile} {
    margin-bottom: 5.33vw;
    background: none;
    border: none;
    padding: 0;
    overflow: visible;
  }
`;

const Track = styled.div`
  min-width: ${styles.pxToVw(122)};
  background: ${colors.drePurple};
  color: #000;
  font-family: "Suisse Int'l";
  ${styles.fontSize(14)}
  letter-spacing: 0.2em;
  margin-left: ${styles.pxToVw(16)};
  padding: ${styles.pxToVw(10)} ${styles.pxToVw(20)};
  text-align: center;
  text-transform: uppercase;
`

const HeadShot = styled.img`
  width: 2.92vw;
  height: 2.92vw;
  border-radius: 100%;
  margin-bottom: 0.89vw;

  ${media.tablet} {
    width: 3.6vw;
    height: 3.6vw;
    border-radius: 3.6vw;
  }
  ${media.mobile} {
    width: 8vw;
    height: 8vw;
    border-radius: 4vw;
  }
  ${media.fullWidth} {
  }
`;

const SpeakerShot = styled.a`
  display: flex;
  align-items: center;
  color: black;
  position: relative;
  cursor: pointer;
  margin-bottom: 0.36vw;
  img {
    border: 2px solid transparent;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0));
    transition: 0.4s;
  }
`;

const SpeakersContainer = styled.div`
  display: flex;

  img {
    margin-right: 0.6vw;
  }

  ${SpeakerShot} {
    p {
      transition: 0.4s;
      position: absolute;
      ${text.fullWidth.OverlineS};
      color: black;
      bottom: -1.19vw;
      width: fit-content;
      opacity: 0;
      left: 50%;
      transform: translateX(-50%);
      transform-origin: center center;
      white-space: nowrap;
      text-align: center;
      padding: 0.3vw 0.6vw;
      background: ${colors.offWhite};
      border: 1px solid ${colors.drePurple};
    }
    ${media.hover} {
      :hover {
        p {
          opacity: 1;
        }
        img {
          border: 2px solid ${colors.drePurple};
          filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
        }
      }
    }
  }

  ${media.tablet} {
    flex-wrap: wrap;
    p {
      ${text.tablet.OverlineS};
    }
  }
  ${media.mobile} {
    img {
      margin-right: 2.7vw;
    }
  }
  ${media.fullWidth} {
  }
`;

const CloseSpeakers = styled(CloseSpeakersSVG)`
  width: 100%;
  height: 100%;
`;

const ExtraSpeakers = styled.div`
  position: absolute;
  background: ${colors.offWhite};
  padding: 1.4vw 0.6vw 0.77vw;
  display: flex;
  flex-direction: column;
  width: fit-content;
  left: 68vw;
  top: -50%;

  border: 1px solid black;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  opacity: 0;
  ${text.fullWidth.BodyXXS};
  z-index: -1;
  button {
    position: absolute;
    width: 1.55vw;
    height: 1.55vw;
    background: black;
    top: -0.77vw;
    right: -0.77vw;
    border-radius: 1.55vw;
    border: none;
    padding: 0;
    outline: none;
  }

  img {
    width: 1.31vw;
    height: 1.31vw;
    margin-right: 0.3vw;
    margin-bottom: 0;
    border: 1px solid white;
  }

  ${media.tablet} {
    top: 7.43vw;
    left: 55.04vw;
    ${text.tablet.BodyXXS};
    button {
      width: 3.12vw;
      height: 3.12vw;
      position: absolute;
      top: -1.56vw;
      right: -1.56vw;
    }
    img {
      width: 2.64vw;
      height: 2.64vw;
      margin-right: 0.6vw;
    }
  }
  ${media.mobile} {
    top: 66.67vw;
    left: 11.15vw;
    padding: 4vw 3.2vw;
    ${text.mobile.BodyS}
    a {
      margin-bottom: 1.6vw;
    }
    img {
      width: 5.9vw;
      height: 5.9vw;
      margin-right: 1.3vw;
    }
    button {
      width: 7vw;
      height: 7vw;
      border-radius: 50%;
      position: absolute;
      top: -2.9vw;
      right: -3.5vw;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;





const Extra = styled.div`
  width: 2.92vw;
  height: 2.92vw;
  border-radius: 100%;
  margin-bottom: 0.89vw;
  color: ${colors.drePurple};
  border: 2px solid ${colors.drePurple};
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.07vw;
  line-height: 110%;

  letter-spacing: -0.02em;

  ${media.hover} {
    :hover {
      ${ExtraSpeakers} {
        opacity: 1;
        transition: 0.4s;
      }
    }
  }
  ${media.tablet} {
    width: 3.6vw;
    height: 3.6vw;
    border-radius: 3.6vw;
    font-size: 1.68vw;
  }

  ${media.mobile} {
    width: 8vw;
    height: 8vw;
    border-radius: 4vw;
    font-size: 3.7vw;
  }
  ${media.fullWidth} {
  }
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${media.mobile} {
    margin: 0;
  }
`;

const SpeakerColumn = styled.div`
  display: flex;
  flex-direction: column;
  left: 52.5vw;
  top: 0.89vw;
  ${media.tablet} {
    width: 11.63vw;
    left: 57.07vw;
    top: 1.8vw;
  }
  ${media.mobile} {
    position: inherit;
    width: 100%;
    margin-bottom: 10.7vw;
    ${Overline} {
      margin-bottom: 3.7vw;
    }
  }
  ${media.fullWidth} {
  }
`;

const TopicTitle = styled.p`
  ${text.fullWidth.H5};
  display: flex;
  line-height: 110%;

  span {
    flex: 1;
  }

  ${media.tablet} {
    ${text.tablet.H5};
    line-height: 110%;
    font-size: 1.92vw;
    font-weight: 600;
    -webkit-line-clamp: 6;
  }
  ${media.mobile} {
    ${text.mobile.H6}

    font-size: 4.3vw;
    -webkit-line-clamp: 6;
  }
`;

const ExpandImg = styled(ExpandButtonSVG)<{ showDescription: boolean; }>`
  width: 1.43vw;
  height: 1.43vw;
  transition: 0.4s;
  .first-line {
    transform-origin: 50% 50%;
    transform: ${(props) => (props.showDescription ? 'scaleY(0)' : 'scaleY(1)')};
    transition: transform 250ms ease-in-out;
  }


  ${media.tablet} {
    width: 2.88vw;
    height: 2.88vw;
  }
  ${media.mobile} {
    width: 6.4vw;
    height: auto;
  }
  ${media.fullWidth} {
  }
`;

const Expand = styled.button<{ isOpen: boolean }>`
  display: flex;
  position: absolute;
  top: 2.38vw;
  right: 1.19vw;
  border: none;
  outline: none;
  background: transparent;
  justify-content: center;
  align-items: center;
  line-height: 100%;
`;

const DREArrow = styled(DREArrowSVG)`
  width: auto;
  height: 0.7em;
  margin-left: ${styles.pxToVw(4.5)};
`;

export default ScheduleEvent;
