import _ from "lodash";
import React from "react";
import styled from "styled-components";
import media from "styles/media";
import text from "styles/text";
import { ReactComponent as BlackBallSVG } from "svg/blackBallSched.svg";
import ScheduleDay from "./ScheduleDay";

type Props = {
  //isLive: boolean;
  allEvents: any;
};

const Schedule = ({
  //isLive,
  allEvents,
}: Props): JSX.Element => {
  const eventsByDay = _.groupBy(allEvents, "date");

  return (
    <Wrapper id="schedule">
      <Overline>03 - Schedule</Overline>
      <Title>
        Program for the <br /> DRE conference
      </Title>
      {Object.keys(eventsByDay).map((key) => (
        <ScheduleDay allEvents={eventsByDay[key]} key={key} />
      ))}
      <BlackBall />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 2.26vw 5.95vw 5vw 13.39vw;
  overflow: hidden;
  position: relative;
  ${media.tablet} {
    padding: 4.08vw 11.99vw 10vw 12.83vw;
  }
  ${media.mobile} {
    padding: 40.6vw 4vw 0;
    overflow: visible;
  }
`;

const Title = styled.h3`
  ${text.fullWidth.H3};
  margin-bottom: 7.44vw;
  position: relative;
  z-index: 1;
  ${media.tablet} {
    ${text.tablet.H3};
    width: 64.51vw;
    margin-bottom: 3.6vw;
  }
  ${media.mobile} {
    ${text.mobile.H3}
    width: 100%;
    margin-bottom: 10.7vw;
  }
`;

const Overline = styled.p`
  ${text.fullWidth.OverlineS};
  margin-bottom: 0.6vw;
  position: relative;
  z-index: 1;
  ${media.tablet} {
    ${text.tablet.Overline};
    margin-bottom: 2.4vw;
  }
  ${media.mobile} {
    ${text.mobile.Overline}
    margin-bottom: 5.3vw;
  }
`;

const BlackBall = styled(BlackBallSVG)`
  position: absolute;
  width: 15.89vw;
  height: 15.89vw;
  left: -10.83vw;
  bottom: 8.33vw;
  z-index: 0;
  ${media.tablet} {
    width: 13.55vw;
    height: 13.55vw;
    left: -7vw;
  }
  ${media.mobile} {
    display: none;
  }
`;

export default Schedule;
