function pxToVw(pixels: number): string {
  return `${Number(pixels / 1680 * 100).toFixed(3)}vw`
}

function fontSize(pixels: number) {
  return `
    font-size: ${pxToVw(pixels)};

    @media (max-width: 1024px) {
      font-size: ${pxToVw(pixels * 2)};
    }
  `
}

export default {
  fontSize,
  pxToVw,
}