import { TabletContext } from "components/layout";
import RegisterNowButton from "components/RegisterNowButton";
import gsap from "gsap";
import heroPerson from "images/HeroPerson.png";
import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import colors from "styles/colors";
import media from "styles/media";
import text from "styles/text";
import { ReactComponent as BallsSVG } from "svg/heroBalls.svg";
import { ReactComponent as BallsTSVG } from "svg/heroBallsTablet.svg";

const Hero = (): JSX.Element => {
  const row1 = useRef<HTMLDivElement>(null);
  const row2 = useRef<HTMLDivElement>(null);
  const row3 = useRef<HTMLDivElement>(null);
  const tablet = useContext(TabletContext);

  useEffect(() => {
    if (row1.current) {
      const tl = gsap.timeline({ repeat: -1 });
      tl.to(".row__1", {
        x: "-100%",
        ease: "none",
        duration: 20,
      }).to(".row__1", { x: 0, duration: 0 });
    }
    if (row2.current) {
      const tl = gsap.timeline({ repeat: -1 });
      tl.to(".row__2", {
        x: "-100%",
        ease: "none",
        duration: 80,
      }).to(".row__2", { x: 0, duration: 0 });
    }
    if (row3.current) {
      const tl = gsap.timeline({ repeat: -1 });
      tl.to(".row__3", {
        x: "-100%",
        ease: "none",
        duration: 20,
      }).to(".row__3", { x: 0, duration: 0 });
    }
  }, []);

  return (
    <Wrapper>
      <Speaker
        src={heroPerson}
        alt="a man sits in front of a computer on a video call"
      />
      {tablet ? <BlackBall1T /> : <BlackBall1 />}

      <TopRow>
        <Overline>April 19th, 2023</Overline> <Line />
        <Overline>11:30AM - 4:00PM ET</Overline> <Line />
        <Overline>VIRTUAL EVENT</Overline>
      </TopRow>
      <Title>Data Reliability Engineering Conference 2023</Title>
      <Row>
        <RegisterNowContainer>
          <RegisterNowButton />
        </RegisterNowContainer>
        {/*
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://formulatedby.typeform.com/to/X3dqGbqK"
        >
          <ApplyToSpeak>Apply To Speak</ApplyToSpeak>
        </a>
        */}
        {/* <Button href="#schedule">See the Schedule</Button> */}
      </Row>

      <Marquee aria-hidden>
        <Row ref={row1}>
          <p className="row__1">
            &nbsp; Observability &nbsp; Version Control &nbsp;
          </p>
          <p className="row__1">
            &nbsp; Observability &nbsp; Version Control &nbsp;
          </p>
        </Row>
        <Row ref={row2}>
          <p className="row__2">
            &nbsp; DRE Conference &nbsp; DRE Conference &nbsp; DRE Conference
            &nbsp; DRE Conference
          </p>
          <p className="row__2">
            &nbsp; DRE Conference &nbsp; DRE Conference &nbsp; DRE Conference
            &nbsp; DRE Conference
          </p>
        </Row>
        <Row ref={row3}>
          <p className="row__3">
            &nbsp; Reliability &nbsp; Discovery &nbsp; Observability &nbsp;
          </p>
          <p className="row__3">
            &nbsp; Reliability &nbsp; Discovery &nbsp; Observability &nbsp;
          </p>
        </Row>
      </Marquee>
    </Wrapper>
  );
};

const BlackBall1T = styled(BallsTSVG)`
  ${media.tablet} {
    width: 100vw;
    height: 97vw;
    left: 0vw;
    top: 6.24vw;
    position: absolute;
    z-index: 0;
    transition: 0.5s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  }
`;

const Speaker = styled.img`
  width: 25.95vw;
  height: 39.76vw;
  position: absolute;
  top: 4.52vw;
  right: 12.86vw;
  z-index: 2;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  ${media.tablet} {
    width: 31.06vw;
    height: 47.6vw;
    right: 18.94vw;
    top: 55.52vw;
  }
  ${media.mobile} {
    width: 56.3vw;
    height: 92vw;
    top: 136vw;
    right: 31.7vw;
    z-index: 2;
  }
`;

const BlackBall1 = styled(BallsSVG)`
  width: 100%;
  height: 89.64vw;
  left: 0;
  top: 2.2vw;
  position: absolute;
  z-index: 0;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  ${media.tablet} {
  }
  ${media.mobile} {
    width: 200%;
    height: 170vw;
    top: 135vw;
    left: -102vw;
    rect[x="1284"] {
      transform: translate(30vw, -25vw);
    }
    rect[x="862"] {
      transform: translate(5vw, 55vw);
    }
  }
`;

const Wrapper = styled.section`
  padding: 15.77vw 0 56.07vw 5.95vw;
  overflow: hidden;
  position: relative;
  transition: padding 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  ${Speaker} {
    top: 7.79vw;
  }
  ${BlackBall1} {
    top: 5.77vw;
  }
  ${media.tablet} {
    padding: 16.19vw 0 100.6vw 11.99vw;

    ${BlackBall1T} {
      top: 14vw;
    }

    ${Speaker} {
      top: 63vw;
    }
  }
  ${media.mobile} {
    padding: 44.53vw 4vw 16vw;

    ${Speaker} {
      top: 148vw;
    }
    ${BlackBall1} {
      top: 147vw;
    }
  }
`;
const Title = styled.h1`
  ${text.fullWidth.H1};
  font-weight: 700;
  width: 46.19vw;
  margin-bottom: 2.38vw;
  position: relative;
  z-index: 1;
  ${media.tablet} {
    ${text.tablet.H1};
    width: 62.95vw;
    margin-bottom: 3.6vw;
  }
  ${media.mobile} {
    ${text.mobile.H1}
    font-weight: 700;
    width: 100%;
    line-height: 100%;
    margin-bottom: 8vw;
  }
`;
const Overline = styled.p`
  ${text.fullWidth.Overline};
  margin-bottom: 1.19vw;
  position: relative;
  z-index: 1;
  ${media.tablet} {
    ${text.tablet.Overline}
    margin-bottom: 2.4vw;
  }
  ${media.mobile} {
    ${text.mobile.Overline}
    margin-bottom: 5.9vw;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  ${media.tablet} {
  }
  ${media.mobile} {
    flex-direction: column;
    a {
      width: 100%;
    }
  }
  ${media.fullWidth} {
  }
`;

const RegisterNowContainer = styled.span`
  margin-right: 1.79vw;
  ${media.tablet} {
  }
  ${media.mobile} {
    margin-bottom: 5.3vw;
    margin-right: 0;
  }
  ${media.fullWidth} {
  }
`;

// const ApplyToSpeak = styled.button`
//   ${buttonAlt};
//   margin-right: 1.79vw;
//   ${media.tablet} {
//     color: black;
//     border: 1px solid black;
//   }
//   ${media.mobile} {
//     margin-bottom: 335.7vw;
//   }
//   ${media.fullWidth} {
//   }
// `;

// const Button = styled.a`
//   ${buttonAlt};
//   margin-right: 1.79vw;
//   ${media.tablet} {
//     color: black;
//     border: 1px solid black;
//   }
//   ${media.mobile} {
//     margin-bottom: 335.7vw;
//   }
// `;

const Marquee = styled.div`
  position: absolute;
  bottom: 13.99vw;
  width: 100%;
  left: 0;
  overflow: hidden;

  ${Row} {
    ${text.fullWidth.Ticker};
    white-space: pre;
    p {
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      background-size: 100%;
      background-clip: text;
      -webkit-text-stroke-color: black;
      background-color: rgba(0, 0, 0, 0);
      -webkit-text-stroke-width: 1px;
    }
  }

  ${Row}:nth-child(2) {
    p {
      text-fill-color: black;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: black;
      -moz-text-fill-color: black;
      background-size: 100%;
      background-clip: text;
      -webkit-text-stroke-color: ${colors.drePurple};
      background-color: rgba(0, 0, 0, 0);
      -webkit-text-stroke-width: 2px;
    }
  }
  ${media.tablet} {
    bottom: 5.4vw;
    ${Row} {
      ${text.tablet.Ticker};
      font-weight: 600;
      p {
        -webkit-text-stroke-width: 0.12vw;
      }
    }

    ${Row}:nth-child(2) {
      p {
        -webkit-text-stroke-width: 0.24vw;
      }
    }
  }
  ${media.mobile} {
    bottom: 150vw;
    ${Row} {
      flex-direction: row;
      margin-bottom: 5.3vw;
      ${text.mobile.Ticker}
    }
  }
`;

const TopRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.19vw;
  p {
    margin: 0;
  }
  ${media.tablet} {
  }
  ${media.mobile} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: -8vw;
    margin-bottom: 8vw;
    ${Overline}:nth-child(1) {
      margin: 0;
    }
  }
`;

const Line = styled.div`
  height: 1.07vw;
  width: 0.15vw;
  margin-right: 1.19vw;
  margin-left: 1.19vw;
  background: ${colors.drePurple};
  ${media.tablet} {
    height: 2.6vw;
    width: 0.25vw;
    margin: 0 2.4vw;
  }
  ${media.mobile} {
    width: 14.67vw;
    height: 0.53vw;
    margin: 4vw 0;
  }
`;

export default Hero;
