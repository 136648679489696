import _ from "lodash";
import React from "react";
import styled from "styled-components";
import styles from "styles";
import colors from "styles/colors";
import media from "styles/media";
import text from "styles/text";
import { ReactComponent as DashedLineSVG } from "svg/dashedLine.svg";
import ScheduleEvent from "./ScheduleEvent";

type Props = {
  //isLive: boolean;
  allEvents: any;
};

const Schedule = ({
  //isLive,
  allEvents,
}: Props): JSX.Element => {
  const eventsByTime = _.groupBy(allEvents, "sortTime");

  const topicCards = Object.keys(eventsByTime).map((eventKey) => {
    const eventsAtTime = eventsByTime[eventKey];
    const isMultiTrack = _.size(eventsAtTime) > 1;

    return (
      <TimeWrapper key={eventKey}>
        <Time>{eventsAtTime[0].time}</Time>
        <Cards className={`cards ${isMultiTrack && "cards---is-multi-track"}`}>
          {eventsAtTime.map((event, i) => (
            <ScheduleEvent
              event={event}
              isMultiTrack={isMultiTrack}
              track={isMultiTrack ? i + 1 : undefined}
            />
          ))}
        </Cards>
      </TimeWrapper>
    );
  });

  return (
    <Wrapper>
      <ViewSpeakers>{allEvents[0].date}</ViewSpeakers>
      <ScheduleContainer>
        <VertLines>
          <DashedLines />
        </VertLines>
        {topicCards}
      </ScheduleContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & + & {
    margin-top: 112px;
  }
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  &:last-child {
    .card {
      margin-bottom: 0;
    }
  }
`;

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  z-index: 1;
  width: 80.65vw;
  border-top: 1px solid black;
  margin-top: 1.67vw;
  padding: 4.58vw 0 1.25vw 0;
  height: auto;
  ${media.tablet} {
    margin-top: 4.44vw;
    width: 76.02vw;
    padding: 4.44vw 0 0 0;
  }
  ${media.mobile} {
    margin-top: 10.7vw;
    padding: 10.7vw 0;
    width: 100%;
  }
  ${media.fullWidth} {
  }
`;

const VertLines = styled.div`
  position: absolute;
  height: calc(100% - 3.45vw);
  overflow: hidden;
  width: 124px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2.2vw;
  ${media.mobile} {
    display: none;
  }
  ${media.fullWidth} {
  }
`;

const ViewSpeakers = styled.button`
  appearance: none;
  -webkit-appearance: none;
  ${text.fullWidth.BtnText};
  border-radius: 5.95vw;
  padding: 0.89vw 2.5vw;
  outline: none;
  border: none;
  background: black;
  color: ${colors.offWhite};
  transition: 0.4s;
  cursor: text;

  ${media.tablet} {
    padding: 1.8vw 2.52vw;
  }
  ${media.mobile} {
    ${text.mobile.BtnText}
    padding: 4vw 5.3vw;
    border-radius: 13.3vw;
  }
  ${media.fullWidth} {
  }
`;

const Time = styled.p`
  ${text.fullWidth.H6};
  ${text.fullWidth.Link};
  width: 124px;
  background: black;
  color: ${colors.offWhite};
  font-size: 18px;
  margin-top: 40px;
  margin-right: auto;
  padding: ${styles.pxToVw(10)} 0;
  position: relative;
  text-align: center;
`;

const Cards = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  gap: 10px;
  margin-left: 40px;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    margin-left: 0;
  }
`;

const DashedLines = styled(DashedLineSVG)`
  height: 6010px;
  width: 2px;
  position: absolute;

  ${media.tablet} {
  }
  ${media.mobile} {
    display: none;
  }
  ${media.fullWidth} {
  }
`;

export default Schedule;
