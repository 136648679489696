import React from "react";
import styled from "styled-components";
import text from "styles/text";
import media from "styles/media";
import dataQualityCampLogo from "images/data-quality-camp-logo.png";
import portableLogo from "images/portable-logo.png";
import snowflakeLogo from "images/snowflake-logo.png";
import selectStarLogo from "images/select-star-logo.png";
import { ReactComponent as ArizeSVG } from "svg/arize-logo.svg";
import { ReactComponent as CastorSVG } from "svg/castor-logo.svg";
import { ReactComponent as CensusSVG } from "svg/census-logo.svg";
import { ReactComponent as BigeyeSVG } from "svg/bigeyeLarge.svg";
import ticketsGuy from "images/ticketsGuy.png";
import { ReactComponent as GradientBallSVG } from "svg/ticketNode.svg";
import { ReactComponent as TopLineSVG } from "svg/ticketLine1.svg";

const Sponsors = (): JSX.Element => {
  return (
    <Wrapper>
      <TopLine />
      <TicketsImage src={ticketsGuy} alt="Man smiling and writing at desk" />
      <GradientBall />
      <Overline>04 - Partners</Overline>
      <Title>
        Provided by leaders in <br /> data technology{" "}
      </Title>
      <LogosWrapper>
        <BigeyeLogo aria-label="Bigeye" />
        <DataQualityCampLogo
          src={dataQualityCampLogo}
          alt="Data Quality Camp"
        />
        <PortableLogo src={portableLogo} alt="Portable" />
        <CastorLogo aria-label="Castor" />
        <CensusLogo aria-label="Census" />
        <ArizeLogo aria-label="Arize" />
        <SnowflakeLogo src={snowflakeLogo} alt="Snowflake" />
        <SelectStarLogo src={selectStarLogo} alt="Select Star" />
      </LogosWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 3.51vw 0;
  overflow: hidden;
  position: relative;
  ${media.mobile} {
    padding: 35vw 0 21vw;
  }
`;

const BigeyeLogo = styled(BigeyeSVG)`
  width: 16.19vw;
  height: 4.5vw;

  ${media.mobile} {
    width: 48vw;
    height: auto;
  }
  ${media.fullWidth} {
  }
`;
const ArizeLogo = styled(ArizeSVG)`
  width: 13.69vw;
  height: 4.76vw;

  ${media.mobile} {
    width: 30.7vw;
    height: auto;
  }
`;

const CensusLogo = styled(CensusSVG)`
  width: 13.69vw;
  height: 4.76vw;

  ${media.mobile} {
    width: 30.7vw;
    height: auto;
  }
`;

const CastorLogo = styled(CastorSVG)`
  width: 13.69vw;
  height: 4.76vw;

  ${media.mobile} {
    width: 30.7vw;
    heighdt: auto;
  }
`;

const DataQualityCampLogo = styled.img`
  width: 5.36vw;
  height: 5.36vw;

  ${media.mobile} {
    width: 35.7vw;
    height: auto;
  }
`;
const PortableLogo = styled.img`
  width: 21.99vw;
  height: 5.36vw;

  ${media.mobile} {
    width: 40vw;
    height: auto;
  }
`;

const SnowflakeLogo = styled.img`
  width: 20.16vw;
  height: 4.8vw;

  ${media.mobile} {
    width: 40vw;
    height: auto;
  }
`;

const SelectStarLogo = styled.img`
  width: 25.3vw;
  height: 3vw;

  ${media.mobile} {
    width: 40vw;
    height: auto;
  }
`;

const LogosWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 66.43vw;
  gap: 48px;

  margin: 0 auto;

  align-items: center;

  ${media.mobile} {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  ${media.fullWidth} {
  }
`;

const Title = styled.h3`
  ${text.fullWidth.H3};
  text-align: center;
  margin-bottom: 7.5vw;
  position: relative;
  z-index: 1;
  ${media.tablet} {
    ${text.tablet.H3};
    margin-bottom: 8.39vw;
  }
  ${media.mobile} {
    ${text.mobile.H3}
    margin-bottom: 16vw;
  }
`;

const Overline = styled.p`
  ${text.fullWidth.OverlineS};
  text-align: center;
  margin-bottom: 1.79vw;
  position: relative;
  z-index: 1;
  ${media.tablet} {
    ${text.tablet.Overline};
    margin-bottom: 2.4vw;
  }
  ${media.mobile} {
    ${text.mobile.Overline}
    margin-bottom: 5.3vw;
  }
`;

const TopLine = styled(TopLineSVG)`
  margin-left: 47.62vw;
  width: 51.67vw;
  height: 30.48vw;
  margin-bottom: 2.98vw;
  position: relative;

  ${media.tablet} {
    width: 79.38vw;
    height: 30.46vw;
    margin-left: 35vw;
  }
  ${media.mobile} {
    display: none;
  }
  ${media.fullWidth} {
  }
`;

const GradientBall = styled(GradientBallSVG)`
  position: absolute;
  width: 7.86vw;
  height: 7.86vw;
  top: 10.89vw;
  right: 18.27vw;
  ${media.tablet} {
    width: 9.47vw;
    height: 9.47vw;
    right: 17.51vw;
  }
`;

const TicketsImage = styled.img`
  width: 31.9vw;
  height: 31.9vw;
  right: -9.05vw;
  top: 7.98vw;
  position: absolute;

  ${media.tablet} {
    width: 38.49vw;
    height: 38.49vw;
    right: -14.87vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

export default Sponsors;
